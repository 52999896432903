import { message, Form, Input, Switch, Button, Tooltip } from 'antd';
import React, { useEffect, useState } from "react";

import ColorPicker from '../ColorPicker';
import UploadLogo from '../UploadLogo';
import { InfoCircleOutlined } from '@ant-design/icons'

export default function Step2({ setContinueBut, setPersLogo, setPersColor }) {
    const [colorBackground, setColorBackground] = useState();
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState();
    const [useColorDefault, setUseColorDefault] = useState(true);
    const [useLogoDefault, setUseLogoDefault] = useState(true);
    const [ColorDefault, setColorDefault] = useState(true);
    const [LogoDefault, setLogoDefault] = useState(true);
    const [nameCard, setNameCard] = useState();
    const [descCard, setDescCard] = useState();

    const badgeFormRef = React.createRef();

    useEffect(() => {
        const nameCard = localStorage.getItem("nome_card");
        const descCard = localStorage.getItem("descrizione_card");
        const persLogo = localStorage.getItem("persLogo");
        const persColor = localStorage.getItem("persColor");
        setNameCard(nameCard);
        setDescCard(descCard);
        if (nameCard && descCard) {
            badgeFormRef.current.setFieldsValue({
                name_card: nameCard,
                desc_card: descCard
            });
            if (persLogo) {
                setLogoDefault(false);
            }
            if (persColor) {
                setColorDefault(false)
            }
            if (useColorDefault && useLogoDefault) {
                setContinueBut(true);
            }
        }
    }, [])

    const beforeUpload = (file) => {
        const isPng = file.type === 'image/png';
        if (!isPng) {
            message.error('Puoi caricare solamente file PNG!');
            setTimeout(() => {
                setLoading(false);
            }, 4000)
        }
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error('L\'immagine non deve superare i 5MB!');
            setTimeout(() => {
                setLoading(false);
            }, 4000)
        }
        if (isPng && isLt5M) {
            // salva logo per inviare succ
            setLoading(false);
            const img = URL.createObjectURL(file)
            setImage(img);
            var reader = new FileReader();
            let temp;
            reader.onloadend = () => {
                temp = reader.result;
                localStorage.setItem('new_logo', temp);
            };
            reader.readAsDataURL(file);
        }
    };

    const preventUpload = () => {
        console.log('prevent');
    }

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        } else { setLoading(false) }
    };

    const onChangeColor = (color) => {
        setColorBackground(color.hex);
        localStorage.setItem('color_pers', color.hex)
        if (useLogoDefault && colorBackground) {
            setContinueBut(true);
        }
        if (!useLogoDefault && image && colorBackground) {
            setContinueBut(true);
        }
    }

    const onChangeCard = (value) => {
        if (value.name_card) {
            setNameCard(value.name_card);
            localStorage.setItem('nome_card', value.name_card);
        }
        if (value.desc_card) {
            setDescCard(value.desc_card);
            localStorage.setItem('descrizione_card', value.desc_card);
        }

        if (nameCard && descCard) {
            setContinueBut(true);
        } else {
            setContinueBut(false);
        }
    }

    const onLogoChange = (value) => {
        if (value) {
            localStorage.removeItem('persLogo');
        } else {
            localStorage.setItem('persLogo', value);
        }
        setPersLogo(!value)
        setUseLogoDefault(!useLogoDefault);
        if (value && useColorDefault) {
            setContinueBut(true);
        } else {
            setContinueBut(false);
        }
    }

    const onColorChange = (value) => {
        if (value) {
            localStorage.removeItem('persColor');
            setColorBackground();
        } else {
            localStorage.setItem('persColor', value);
        }
        setPersColor(!value);
        setUseColorDefault(!useColorDefault);
        if (value && useLogoDefault) {
            setContinueBut(true);
        } else {
            setContinueBut(false);
        }
    }

    useEffect(() => {
        if (!useLogoDefault && image) {
            setContinueBut(true);
        }
    }, [image])

    useEffect(() => {
        if (!useColorDefault && colorBackground) {
            setContinueBut(true);
        }
    }, [colorBackground])

    const onFinish = (value) => {
        console.log(value)
    }

    const onModifyLogo = () => {
        localStorage.removeItem('new_logo');
        localStorage.removeItem('persLogo');
        setLogoDefault(true);
    }
    const onModifyColor = () => {
        localStorage.removeItem('color_pers');
        setColorDefault(true);
        localStorage.removeItem('persColor');
    }

    return (
        <div style={{ width: '90%', marginLeft: '5%', display: 'flex', alignItems: 'center', marginTop: '5%', marginBottom: '5%' }}>
            <div className="upk-create-form-container" >
                <div
                    style={{
                        marginBottom: 10, marginTop: 20,
                        fontFamily: 'Quicksand-Light', fontSize: 18, color: 'black', textAlign: 'left',
                    }}>
                    Inserisci il nome e la descrizione del tuo Badge
                </div>
                <Form
                    layout="vertical"
                    size='large'
                    requiredMark={false}
                    onValuesChange={onChangeCard}
                    ref={badgeFormRef}
                    onFinish={onFinish}
                >
                    <Form.Item label={<div style={{ marginLeft: 10 }}>TITOLO CARD</div>}
                        name="name_card"
                        labelCol={{ offset: 0 }}
                        style={{ fontFamily: 'Quicksand-Regular', fontSize: 12, color: '#626476' }}
                        rules={[
                            {
                                required: true,
                                message: 'Inserisci un nome',

                            },
                        ]}>
                        <Input style={{ borderRadius: 12, height: 60, marginTop: -15, width: '80%' }} />
                    </Form.Item>
                    <Form.Item label={<div style={{ marginLeft: 10 }}>DESCRIZIONE CARD</div>}
                        name="desc_card"
                        labelCol={{ offset: 0 }}
                        style={{ fontFamily: 'Quicksand-Regular', fontSize: 12, color: '#626476' }}
                        rules={[
                            {
                                required: true,
                                message: 'Inserisci una breve descrizione',

                            },
                        ]}>
                        <Input style={{ borderRadius: 12, height: 60, marginTop: -15, width: '80%' }} />
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        {LogoDefault &&
                            <>
                                <div style={{ display: 'flex', paddingRight: 20, paddingTop: 20, paddingBottom: 20 }}>
                                    Utilizza Logo preimpostato
                                    <Switch style={{ marginLeft: 25 }} checked={useLogoDefault} onChange={onLogoChange} />
                                </div>

                                {!useLogoDefault &&
                                    <>
                                        <div className='upk-create-logo-container'
                                            style={{ paddingTop: 20, display: 'flex', flexDirection: 'row' }}>
                                            <UploadLogo beforeUpload={beforeUpload} handleChange={handleChange} preventUpload={preventUpload} image={image} loading={loading} />
                                            <span> </span>
                                            <Tooltip title="Il logo deve essere in formato png">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)', marginTop: 10
                                                    }}
                                                />
                                            </Tooltip>
                                        </div>
                                    </>
                                }
                            </>
                        }

                        {ColorDefault &&
                            <>
                                <div style={{ display: 'flex', paddingRight: 20, paddingTop: 20, paddingBottom: 20, marginBottom: 10 }}>
                                    Utilizza Sfondo preimpostato
                                    <Switch style={{ marginLeft: 11 }} checked={useColorDefault} onChange={onColorChange} />
                                </div>
                                {!useColorDefault &&
                                    <>
                                        <div className='upk-create-color-container'
                                            style={{ paddingBottom: 20, display: 'flex', flexDirection: 'row' }}>
                                            <ColorPicker onChangeColor={onChangeColor} buttonVisible={false} />
                                            <div style={{ marginLeft: 15 }}>
                                                <div style={{
                                                    fontFamily: 'Quicksand-SemiBold',
                                                    fontSize: 12,
                                                }}>Selezionato:</div>
                                                <div style={{ width: 50, height: 50, borderRadius: 20, borderWidth: 1, borderColor: '#626476', backgroundColor: colorBackground, marginLeft: 15, marginTop: 10 }}>
                                                </div>


                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </Form>
                {!LogoDefault &&
                    <div style={{ textAlign: 'center' }}>
                        <Button type="primary" shape='round'
                            onClick={onModifyLogo}
                            style={{
                                alignSelf: 'center',
                                marginTop: 20,
                                marginBottom: 20,
                                fontFamily: 'Quicksand-SemiBold',
                                fontSize: 16, backgroundColor: '#3048C1',
                                height: 35,
                            }}>
                            Modifica Logo
                        </Button>
                    </div>}
                {!ColorDefault &&
                    <div style={{ textAlign: 'center' }}>
                        <Button type="primary" shape='round'
                            onClick={onModifyColor}
                            style={{
                                marginTop: 20,
                                marginBottom: 20,
                                fontFamily: 'Quicksand-SemiBold',
                                fontSize: 16, backgroundColor: '#3048C1',
                                height: 35,
                            }}>
                            Modifica Colore
                        </Button>
                    </div>}
            </div>

        </div>
    );
};