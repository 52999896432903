import React, { useEffect, useState, useContext } from "react";
import { ReactComponent as EyeOutlined } from "./../../assets/svg/EyeOutlined.svg";
import { ReactComponent as EyeInvisibleOutlined } from "./../../assets/svg/EyeInvisibleOutlined.svg";
import { WebContext } from "./../../store/web-context";
import { resetPsw, set_password } from "./../../services/api";

import { Button, Divider, Form, Input, message } from 'antd';

export default function FormProfile({email}) {
    const [modifyPsw, setModifyPsw] = useState(false);
    const [state, dispatch] = useContext(WebContext);

    const pswFormRef = React.createRef();

    const {
        isLoadingPage,
        setPassword,
        token,
    } = state;  

    const onFinishPsw = async (values) => {
        console.log("Success:", values);
        const data = {
            password_old:values.oldpassword,
            password: values.password,
            password_confirm: values.password_confirm
        };
        try {
            const authToken = localStorage.getItem('access_token');
            await resetPsw(data, authToken);
            message.success('La password è stata cambiata');

            pswFormRef.current.resetFields();
            pswFormRef.current.setFieldsValue({
                oldpassword: 'password'
            });
            setModifyPsw(false);

        } catch (error) {
            dispatch({
                type: "set_password_fail",
                payload: error,
            });
            message.error(error);
        }
    };

    const onFinishFailedPsw = (values) => {
        console.log('Finish failed:', values);
    };

    const onModifyPsw = () => {
        setModifyPsw(true);
        pswFormRef.current.setFieldsValue({
            oldpassword: '',
        });
    };

    const onCancel = () => {
        setModifyPsw(false);
        pswFormRef.current.resetFields();
        pswFormRef.current.setFieldsValue({
            oldpassword: 'password',

        });
    };

    return (
        <div className="upk-profile-form-container" >
            <Form requiredMark={false}
                className="upk-profile-container"
                fields={[
                    {
                      name: ['email'],
                      value: email,
                    },
                  ]}
            >
                <Form.Item label={<div style={{marginLeft:10}}>Email</div>}
                    name="email"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ fontFamily: 'Quicksand-Regular', fontSize: 12, color: '#626476' }}
                    rules={[
                        {
                            required: true,
                            message: 'Inserisci un E-mail!',
                            type: "email",
                        },
                    ]}
                    >
                    <Input style={{ height: 60, marginTop: -10,borderWidth:0, backgroundColor:'transparent', paddingBottom:0 }} disabled={true} />
                </Form.Item>
            </Form>

            <Divider />

            <Form requiredMark={false}
                className="upk-profile-container"
                onFinish={onFinishPsw}
                onFinishFailed={onFinishFailedPsw}
                ref={pswFormRef}
            >
                {!modifyPsw &&
                    <Form.Item label={<div style={{marginLeft:10}}>Password Attuale</div>}
                        name="oldpassword"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ fontFamily: 'Quicksand-Regular', fontSize: 12, color: '#626476' }}
                        initialValue='password'>
                        <Input style={{ borderRadius: 12, height: 60, marginTop: -10 }}
                            disabled={true} type="password"
                            />
                    </Form.Item>
                }
                {modifyPsw &&
                    <>
                    <Form.Item label={<div style={{marginLeft:10}}>Password Attuale</div>}
                            name="oldpassword"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ fontFamily: 'Quicksand-Regular', fontSize: 12, color: '#626476' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Inserisci la vecchia Password',
                                },
                            ]}>
                            <Input.Password style={{ borderRadius: 12, height: 60, marginTop: -10 }}
                                disabled={!modifyPsw}
                                iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)} />
                        </Form.Item>
                        <Form.Item label={<div style={{marginLeft:10}}>Nuova Password</div>}
                            name="password"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ fontFamily: 'Quicksand-Regular', fontSize: 12, color: '#626476' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Inserisci la nuova Password',
                                },
                            ]}>
                            <Input.Password style={{ borderRadius: 12, height: 60, marginTop: -10 }}
                                disabled={!modifyPsw}
                                iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)} />
                        </Form.Item>
                        <Form.Item label={<div style={{marginLeft:10}}>Conferma Nuova Password</div>}
                            name="password_confirm"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ fontFamily: 'Quicksand-Regular', fontSize: 12, color: '#626476' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Ripeti Password',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error("Le due password inserite non sono identiche!")
                                        );
                                    },
                                }),
                            ]}>
                            <Input.Password style={{ borderRadius: 12, height: 60, marginTop: -10 }} disabled={!modifyPsw}
                                iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)} />
                        </Form.Item>
                    </>
                }
                <Form.Item>
                    {!modifyPsw &&
                        <Button
                            htmlType="button" onClick={onModifyPsw}
                            shape="round"
                            style={{
                                fontFamily: 'Quicksand-Bold', fontSize: 16, height: 40, margin: 10, borderWidth:0, width:100
                            }}>
                            MODIFICA
                        </Button>}
                    {modifyPsw &&
                        <>
                            <Button
                                htmlType="button" onClick={onCancel}
                                shape="round"
                                style={{
                                    margin: 10,
                                    fontFamily: 'Quicksand-Bold', fontSize: 16, height: 40, borderWidth:0, width:100
                                }}>
                                ANNULLA
                            </Button>
                            <Button
                                htmlType="submit"
                                shape="round"
                                style={{
                                    backgroundColor: '#3048C1', color: 'white', margin: 10,
                                    fontFamily: 'Quicksand-Bold', fontSize: 16, height: 40, width:100
                                }}>
                                SALVA
                            </Button>
                        </>
                    }
                </Form.Item>
            </Form>
        </div>
    );
};