import { message } from 'antd';
import { useEffect, useState } from 'react';
import { profileData } from '../../services/api';
import BadgePreview from '../default/BadgePreview';

export default function Step4({ setContinueBut }) {
    const [imageUrl, setImageUrl] = useState();
    const [colorBackground, setColorBackground] = useState();
    const [title, setTitle] = useState();
    const [subtitle, setSubtitle] = useState();

    useEffect(() => {
        let imgDefault = false;
        let colorDefault = false;

        const token = localStorage.getItem('access_token');
        profileData(token)
            .then((data) => {
                if (!data.default_logo) {
                } else {
                    let img = data.default_logo.img;
                    img = `data:image/png;base64,${img}`
                    imgDefault = img;
                }
                if (!data.default_sfondo) {
                } else {
                    colorDefault = data.default_sfondo;
                }
                setLogoColor(imgDefault, colorDefault);
            })
            .catch((error) => {
                console.log("fail", error);
                message.error(error);
            });


    }, [])

    const setLogoColor = (imgDefault, colorDefault) => {
        const nameBadge = localStorage.getItem('nome_card');
        const descBadge = localStorage.getItem('descrizione_card');
        const persLogo = localStorage.getItem('persLogo');
        const persColor = localStorage.getItem('persColor');
        setTitle(nameBadge);
        setSubtitle(descBadge);
        let continueLogo = false;
        let continueColor = false;
        if (persLogo) {
            const newLogo = localStorage.getItem('new_logo');
            setImageUrl(newLogo);
            continueLogo = true;
        } else {
            if (imgDefault) {
                setImageUrl(imgDefault);
                continueLogo = true;
            }
        }

        if (persColor) {
            const backColor = localStorage.getItem('color_pers');
            setColorBackground(backColor);
            continueColor = true;
        } else {
            if (colorDefault) {
                setColorBackground(colorDefault);
                continueColor = true;
            }
        }

        if (continueColor && continueLogo) {
            setContinueBut(true);
        }
    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            {imageUrl && colorBackground &&
                <>
                    <div
                        style={{
                            marginBottom: 60,
                            fontFamily: 'Quicksand-Light', fontSize: 18, color: 'black', textAlign: 'center',
                        }}>
                        Ecco la preview del tuo badge:
                    </div>
                    <BadgePreview imageUrl={imageUrl} colorBackground={colorBackground} title={title} subtitle={subtitle} />
                </>
            }
            {!colorBackground &&
                <div style={{
                    marginBottom: 10, marginLeft:'5%', width:'90%',
                    fontFamily: 'Quicksand-Light', fontSize: 18, color: 'black', textAlign: 'left',
                }}>
                    - Devi Scegliere un colore di sfondo o impostarne uno predefinito nelle impostazioni
                </div>
            }
            {!imageUrl &&
                <div style={{
                    marginBottom: 10,  marginLeft:'5%', width:'90%',
                    fontFamily: 'Quicksand-Light', fontSize: 18, color: 'black', textAlign: 'left',
                }}>
                    - Devi Scegliere un logo o impostarne uno predefinito nelle impostazioni
                </div>
            }
        </div>
    );
};