import React, { useState, useEffect, useContext } from "react";
import { Layout, Button, Divider, message } from 'antd';

import SiderLogin from "../components/auth/SiderLogin";
import FormLogin from "../components/auth/FormLogin";
import { WebContext } from "../store/web-context";
import FormRegister from "../components/auth/FormRegister";
import FormSetPassword from "../components/auth/FormSetPassword";
import { login, register, resetPassword, set_password } from "../services/api";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";

const { Sider, Content } = Layout;

const { innerWidth, innerHeight } = window;

const Login = () => {
    const [state, dispatch] = useContext(WebContext);
    const [registerNew, setRegisterNew] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const navigate = useNavigate();

    const {
        isLoadingPage,
        setPassword,
        token,
    } = state;

    const setToken = (token) => {
        localStorage.setItem("access_token", token);
    };

    const onLoginSuccess = async (values) => {
        console.log("Success:", values);
        const data = {
            ...values,
        };
        dispatch({ type: "login_start" });
        try {
            const response = await login(data);
            const { flg_reset_password } = response;
            localStorage.setItem('email', data.email);
            dispatch({
                type: flg_reset_password
                    ? "login_success_set_password"
                    : "login_success",
                payload: { user: response, token: response.token },
            });
            if (!flg_reset_password) {
                setToken(response.token);
                navigate("/dashboard");
            }
        } catch (error) {
            dispatch({
                type: "login_fail",
                payload: error,
            });
            message.error(error);
        }
    };

    const onRegisterSuccess = async (values) => {
        console.log("Success:", values);
        const data = {
            ...values,
        };
        try {
            await register(data, token);
            setRegisterNew(false);
            message.success(
                "E' stata inviata un'email con una password temporanea"
            );
        } catch (error) {
            message.error(error);
        }
    };

    const onForgotPasswordSuccess = async (values) => {
        console.log("Success:", values);
        const data = {
            ...values,
        };
        try {
            await resetPassword(data, token);
            setForgotPassword(false);
            message.success(
                "E' stata inviata un'email per effettuare il reset della password"
            );
        } catch (error) {
            message.error(error);
        }
    };

    const onSetPasswordSuccess = async (values) => {
        console.log("Success:", values);
        const data = {
            ...values,
        };
        dispatch({ type: "set_password_start" });
        try {
            await set_password(data, token);

            dispatch({
                type: "set_password_success",
            });
            setToken(token);
            navigate("/dashboard");
        } catch (error) {
            dispatch({
                type: "set_password_fail",
                payload: error,
            });
            message.error(error);
        }
    };

    const onLoginFail = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const onSetPasswordFail = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onForgotPasswordFail = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const onRegisterFail = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const onForgotPassword = () => {
        setForgotPassword(true);
    };
    const onBackForgotPassword = () => {
        setForgotPassword(false);
    };
    const onRegister = () => {
        setRegisterNew(true);
    };

    const onBackRegister = () => {
        setRegisterNew(false);
    };

    if (isLoadingPage) {
        return <Loading />;
    } else {
        return (
            <>
                <Layout style={{ height: "100vh", overflow: 'hidden' }}>
                    {innerWidth > 1000 &&
                        <Sider width={"50%"} style={{ backgroundColor: '#F3F5F9', paddingTop: '30vh' }}>
                            <SiderLogin />
                        </Sider>}
                    <Content className="upk-login-content"
                        style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowY: 'scroll' }}>
                        <div className="upk-login-content-container" style={{
                            width: '80%', marginTop: innerWidth < 1400 ? innerHeight < 300 ? '30%' : '15%' : '15%',
                            marginBottom: '10%'
                        }}>
                            <div className="upk-login-content-title-container" style={{ textAlign: 'left' }}>
                                <div className="upk-login-content-title" style={{ fontSize: 48, color: '#060C43', fontFamily: 'Quicksand-Regular' }}>
                                    Benvenuto su <span style={{ color: '#094795', fontFamily: 'Quicksand-Bold' }}><span style={{ color: '#3360EF' }}>U</span>Pocket</span>
                                </div>
                                {registerNew ? (
                                    <div className="upk-login-content-subtitle" style={{ fontSize: 16, color: '#626476', fontFamily: 'Quicksand-Regular' }}>
                                        Inserisci la tua E-mail per registrarti
                                    </div>
                                ) : (
                                    !setPassword ? (
                                        !forgotPassword ? (
                                            <div className="upk-login-content-subtitle" style={{ fontSize: 16, color: '#626476', fontFamily: 'Quicksand-Regular' }}>
                                                Inserisci le tue credenziali per accedere
                                            </div>
                                        ) : (
                                            <div className="upk-login-content-subtitle" style={{ fontSize: 16, color: '#626476', fontFamily: 'Quicksand-Regular' }}>
                                                Inserisci la tua E-mail
                                            </div>
                                        )
                                    ) : (
                                        <div className="upk-login-content-subtitle" style={{ fontSize: 16, color: '#626476', fontFamily: 'Quicksand-Regular' }}>
                                            Inserisci una tua Password personale.
                                        </div>
                                    )
                                )}
                            </div>

                            {registerNew ? (
                                <FormRegister onFinish={onRegisterSuccess}
                                    onFinishFailed={onRegisterFail}
                                />
                            ) : (
                                !setPassword ? (
                                    !forgotPassword ? (
                                        <FormLogin
                                            onFinish={onLoginSuccess}
                                            onFinishFailed={onLoginFail}
                                        />
                                    ) : (
                                        <FormRegister onFinish={onForgotPasswordSuccess}
                                            onFinishFailed={onForgotPasswordFail}
                                        />
                                    )
                                ) : (
                                    <FormSetPassword onFinish={onSetPasswordSuccess}
                                        onFinishFailed={onSetPasswordFail}
                                    />
                                )
                            )}


                            {!registerNew && !setPassword && !forgotPassword &&
                                <div className="upk-login-forgot-register-container" style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginTop: -15 }}>
                                    <Button type="link"
                                        onClick={onForgotPassword}
                                        style={{ color: '#023AAE', fontFamily: 'Quicksand-Medium', fontSize: 14, textAlign: 'center', marginBottom: 5 }}
                                    >Ho dimenticato la password</Button>
                                    <Divider style={{height:0.1, backgroundColor: '#094795'}}/>

                                    <div className="upk-login-register"
                                        style={{ color: '#07122F', fontFamily: 'Quicksand-Regular', fontSize: 14, textAlign: 'center', marginTop: 5 }}
                                    >
                                        Non hai un Account?<Button type="link" onClick={onRegister} style={{ fontFamily: 'Quicksand-SemiBold', color: '#094795', marginLeft: -12 }}>Registrati</Button>
                                    </div>
                                </div>
                            }

                            {registerNew &&
                                <div className="upk-login-register"
                                    style={{ color: '#07122F', fontFamily: 'Quicksand-Regular', fontSize: 14, textAlign: 'center', marginTop: 5 }}
                                >
                                    <Button type="link" onClick={onBackRegister} style={{ fontFamily: 'Quicksand-SemiBold', color: '#094795', marginLeft: -12 }}>Torna indietro</Button>
                                </div>
                            }

                            {forgotPassword &&
                                <div className="upk-login-register"
                                    style={{ color: '#07122F', fontFamily: 'Quicksand-Regular', fontSize: 14, textAlign: 'center', marginTop: 5 }}
                                >
                                    <Button type="link" onClick={onBackForgotPassword} style={{ fontFamily: 'Quicksand-SemiBold', color: '#094795', marginLeft: -12 }}>Torna indietro</Button>
                                </div>
                            }
                        </div>
                    </Content>
                </Layout>

            </>
        );
    }
};

export default Login;

