import React, { useState } from "react";
import { Button, Layout } from 'antd';
import { ReactComponent as LoginLogoDashboard } from "./../assets/svg/LoginLogoDashboard.svg";
import { ReactComponent as DashboardIcon } from "./../assets/svg/DashboardIcon.svg";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../services/clearStorage";
const { Sider, Content } = Layout;
const { innerWidth, innerHeight } = window;

const Dashboard = () => {

    const navigate = useNavigate();

    const onStart = () => {
        clearLocalStorage();
        navigate('/dashboard/create');
    };

    return (
        <>
            <Layout style={{ height: "100vh" }}>
                <Content style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', overflow: 'hidden' }}>
                    <div className="upk-dashboard-content-title-container" style={{ marginTop:-40, textAlign: 'center', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                        <div className="upk-dashboard-logo-container" style={{ textAlign: 'center' }}>
                            <LoginLogoDashboard />
                        </div>
                        <div className="upk-dashboard-content-title" style={{ fontSize: innerWidth > 600 ? 48 : 25, color: '#094795', fontFamily: 'Quicksand-Bold' }}>
                            <span style={{ color: '#3360EF' }}>U</span>Pocket<br />
                            <span style={{ color: '#1C395D' }}>Digitalizza il tuo </span><span style={{ color: '#3360EF' }}>Badge</span>
                        </div>
                        <div className="upk-dashboard-content-subtitle"
                            style={{
                                fontSize: innerWidth > 600 ? 18 : 11, color: '#1C395D',
                                fontFamily: 'Quicksand-Medium', width: '25em', alignSelf: 'center', marginTop: 15
                            }}>
                            Crea, personalizza e digitalizza la tua tessera sul nostro portale per aggiungerla al tuo Pocket.
                        </div>
                    </div>
                    <Button
                        type="primary" shape="round"
                        style={{ margin: 20, backgroundColor: '#3048C1', width: 130, height: 45, fontFamily: 'Quicksand-SemiBold', fontSize: 18 }}
                        onClick={onStart}
                    >
                        INIZIA
                    </Button>
                </Content>
                {innerWidth > 850 &&
                    <Sider width={"50%"} style={{ backgroundColor: 'white' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20vh' }}>
                            <DashboardIcon />
                        </div>
                    </Sider>}
            </Layout>
        </>
    );
};

export default Dashboard;