import React from "react";

const { innerWidth, innerHeight } = window;

export default function BadgePreview({ imageUrl, colorBackground, title = 'CODICE ACCESSO CASA', subtitle = 'MARIO ROSSI' }) {

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div
        style={{
          backgroundColor: colorBackground ? colorBackground : '#2159E7',
          width: 280,
          height: 100,
          borderRadius: 18,
          display: 'flex',
          flexDirection: 'row'
        }}>
        <div style={{
          marginTop: 20,
          marginLeft: 20
        }}>

          {imageUrl ? (
            <img
              src={imageUrl}
              alt="logo"
              style={{
                width: 60, height: 60, borderRadius: 60,
              }}
            />) :
            <div style={{
              width: 60, height: 60, borderRadius: 60,
              backgroundColor: 'white',
            }} />
          }
        </div>
        <div style={{
          color: colorBackground == '#ffe86f' ? 'black' : 'white',
          fontFamily: 'Quicksand-Bold',
          fontSize: 15,
          marginTop: 25,
          marginLeft: 15
        }}>
          <div>
            {title}
          </div>
          <div style={{
            fontFamily: 'Quicksand-Regular'
          }}>
            {subtitle}
          </div>
        </div>
      </div>
    </div>
  );
};