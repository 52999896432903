import './App.css';
import React from "react";
import MainRoutes from './components/MainRoutes';
import { WebContextProvider } from './store/web-context';

function App() {
  return (
    <WebContextProvider>
      <MainRoutes />
    </WebContextProvider>
  );
}

export default App;
