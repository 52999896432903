import {
  UserOutlined,
  QrcodeOutlined,
  StarOutlined,
  MenuOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import logo from './../assets/logo.png';
import { Layout, Menu, Modal, message } from 'antd';
import React, { useState, useContext } from 'react';
import { WebContext } from "../store/web-context";
import { useNavigate } from 'react-router-dom';
import { logout } from '../services/api';
import { clearLocalStorage } from '../services/clearStorage';
import { ReactComponent as SvgHome } from "./../assets/svg/SvgHome.svg";
import { ReactComponent as SvgHomeWhite } from "./../assets/svg/SvgHomeWhite.svg";
import { ReactComponent as SvgFavDefault } from "./../assets/svg/SvgFavDefault.svg";
import { ReactComponent as SvgFavDefaultWhite } from "./../assets/svg/SvgFavDefaultWhite.svg";
import { ReactComponent as SvgProfile } from "./../assets/svg/SvgProfile.svg";
import { ReactComponent as SvgProfileWhite } from "./../assets/svg/SvgProfileWhite.svg";
import { ReactComponent as SvgLogout } from "./../assets/svg/SvgLogout.svg";
import { ReactComponent as SvgLogoutWhite } from "./../assets/svg/SvgLogoutWhite.svg";
import { ReactComponent as SvgBadges } from "./../assets/svg/SvgBadges.svg";
import { ReactComponent as SvgBadgesWhite } from "./../assets/svg/SvgBadgesWhite.svg";
const { Content, Sider, Header } = Layout;

const { innerWidth, innerHeight } = window;

const SideMenu = ({ children }) => {
  const [state, dispatch] = useContext(WebContext);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const items = [
    {
      key: 1,
      label: 'Digitalizza Badge',
      icon: <div style={{ width: 20, marginTop: 8 }}><SvgHome /></div>,
    },
    {
      key: 2,
      label: 'Imposta Badge preferito',
      icon: <div style={{ width: 20, marginTop: 8 }}><SvgFavDefault /></div>
    },
    {
      key: 3,
      label: 'I miei Badge',
      icon: <div style={{ width: 20, marginTop: 8 }}><SvgBadges /></div>
    },
    {
      key: 4,
      label: 'Il mio Profilo',
      icon: <div style={{ width: 20, marginTop: 8 }}><SvgProfile /></div>
    },
    {
      key: 5,
      label: 'Logout',
      icon: <div style={{ width: 20, marginTop: 10 }}><SvgLogout /></div>,
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onLogout = async () => {
    const hide = message.loading("Logout in corso..", 0);
    dispatch({ type: "logout_start" });
    try {
      await logout(state.token);

      dispatch({
        type: "logout_success",
      });
      localStorage.removeItem("access_token");
      clearLocalStorage();
      setIsModalOpen(false);
      navigate("/login");
      setTimeout(hide, 100);
    } catch (error) {
      dispatch({
        type: "logout_fail",
        payload: error,
      });
      setTimeout(hide, 100);
      message.error("Errore durante il logout");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClick = (e) => {
    if (e.key == 1) {
      navigate('/dashboard');
    } else if (e.key == 2) {
      navigate('/dashboard/default');
    } else if (e.key == 3) {
      navigate('/dashboard/badges');
    } else if (e.key == 4) {
      navigate('/dashboard/profile');
    } else if (e.key == 5) {
      showModal();
    }
  };

  return (
    <>
      {innerWidth <= 1000 ? (
        <>
          <Layout
            style={{
              height: '100vh',
              overflow: 'hidden',
            }}>
            <Header style={{
              position: 'fixed',
              zIndex: 1,
              width: '100%',
              backgroundColor: '#094795',
              height: 50
            }} >
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    fontSize: 30, display: 'flex', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: 'white', borderRadius: 50, width: 35, height: 35, margin: 7, position: 'absolute', left: 15
                  }}>
                  <img onClick={() => {
                    navigate('/dashboard');
                  }} src={logo} alt="UPocket" width={25} height={25} style={{  }} />
                </div>
                <div style={{ display: 'flex', position: 'absolute', right: 0 }}>
                  <div style={{ width: 22, height: 45, marginRight: 20 }} onClick={() => { onClick({ key: 1 }) }}>
                    <SvgHomeWhite />
                  </div>
                  <div style={{ width: 22, height: 45, marginRight: 20 }} onClick={() => { onClick({ key: 2 }) }}>
                    <SvgFavDefaultWhite />
                  </div>
                  <div style={{ width: 22, height: 45, marginRight: 20 }} onClick={() => { onClick({ key: 3 }) }}>
                    <SvgBadgesWhite />
                  </div>
                  <div style={{ width: 22, height: 45, marginRight: 20 }} onClick={() => { onClick({ key: 4 }) }}>
                    <SvgProfileWhite />
                  </div>
                  <div style={{ width: 22, height: 45, marginRight: 10 }} onClick={() => { onClick({ key: 5 }) }}>
                    <SvgLogoutWhite />
                  </div>
                </div>
              </div>
            </Header>
            <Content style={{
              backgroundColor: 'white',
              paddingTop: 45
            }}>
              {children}
            </Content>
          </Layout>
        </>
      ) : (
        <>
          <Layout style={{
            height: '100vh',
            overflowX: 'hidden',
          }}>
            <Header
              style={{
                backgroundColor: '#05418D',
              }}>
              <div style={{
                fontSize: 30, display: 'flex', justifyContent: 'center', alignItems: 'center',
                backgroundColor: 'white', borderRadius: 50, width: 50, height: 50, margin: 7, marginLeft: 20, position: 'absolute', left: 0
              }}
                onClick={() => {
                  navigate('/dashboard');
                }}>
                <img src={logo} alt="UPocket" width={innerWidth <= 1000 ? 20 : 40} />
              </div>
            </Header>

            <Layout
              style={{
                overflow: 'hidden',
              }}
            >
              <Sider style={{ backgroundColor: '#f5f2f2', alignSelf: 'stretch' }}
                width={'20em'}
                zeroWidthTriggerStyle={{ display: 'none' }}
                theme={'dark'}>
                <Menu defaultSelectedKeys={[0]} selectedKeys={[null]} mode="inline" items={items} style={{ backgroundColor: '#F5F2F2', paddingTop: 20, paddingLeft: 10 }}
                  onClick={onClick}
                />
              </Sider>
              <Content
                style={{
                  backgroundColor: 'white',
                }}
              >
                {children}
              </Content>
            </Layout>
          </Layout>
        </>
      )}
      < Modal
        title="Vuoi effettuare il Logout?" open={isModalOpen} onOk={onLogout} onCancel={handleCancel} centered >
        <div>
          Sei sicuro di voler uscire dalla Web App?
        </div>
      </Modal >
    </>
  );
};

export default SideMenu;
