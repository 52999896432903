import { Modal, Form, Input, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import {
    QuestionCircleOutlined,
} from '@ant-design/icons';

export default function Step1({ setContinueBut }) {
    const [value, setValue] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const codeFormRef = React.createRef();

    useEffect(() => {
        const code = localStorage.getItem("code");
        const codeType = localStorage.getItem("code_type");
        if (code) {
            setValue(codeType);
            codeFormRef.current.setFieldsValue({
                code: code,
            });
            setContinueBut(true);
        } else {
            localStorage.setItem("code_type", 'Q');
            setValue('Q');
        }
    }, [])


    const onChange = (e) => {
        setValue(e.target.value);
        localStorage.setItem("code_type", e.target.value);
    };

    const onChangeCode = (value) => {
        if (value.code) {
            localStorage.setItem("code", value.code);
            setContinueBut(true);
        } else {
            setContinueBut(false);
        }
    };

    const info = () => {
        Modal.info({
            title: 'Qrcode o Barcode?',
            content: (
                <div>
                    Il Qrcode consente di inserire tutti i tipi di dati come link, testi e numeri. I Barcode seguono invece alcune regole a seconda della tipologia utilizzata.  
                </div>
            ),
            onOk() { },
        });
    };

    return (
        <div style={{ width: '90%', marginLeft: '5%', display: 'flex', alignItems: 'center', marginTop: '5%', marginBottom: '5%' }}>
            <div className="upk-create-form-container">
                <div
                    style={{
                        marginBottom: 10,
                        fontFamily: 'Quicksand-Light', fontSize: 18, color: 'black', textAlign: 'left',
                    }}>
                    Inserisci il codice del tuo Qrcode/Barcode
                </div>
                <Form
                    layout="vertical"
                    onValuesChange={onChangeCode}
                    size='large'
                    requiredMark={false}
                    ref={codeFormRef}
                >
                    <Form.Item label={<div style={{marginLeft:10}}>CODICE</div>}
                        name="code"
                        labelCol={{ offset: 0 }}
                        style={{ fontFamily: 'Quicksand-Regular', fontSize: 12, color: '#626476', }}
                        rules={[
                            {
                                required: true,
                                message: 'Inserisci un Codice',
                            },
                        ]}>
                        <Input style={{ borderRadius: 12, height: 60, marginTop: -15, width: '80%' }} />
                    </Form.Item>
                    <div className='upk-create-radio-qr'
                        style={{ marginTop: 50, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <div
                            style={{
                                marginBottom: 30,
                                fontFamily: 'Quicksand-Light', fontSize: 18, color: 'black', textAlign: 'left',
                            }}>
                            Seleziona se il tuo codice rappresenta un Qrcode o un Barcode<span> </span>
                            <QuestionCircleOutlined onClick={info} />
                        </div>
                        <Radio.Group onChange={onChange} value={value}>
                            <Radio value={'Q'}>QRCODE</Radio>
                            <Radio value={'B'}>BARCODE</Radio>
                        </Radio.Group>
                    </div>

                </Form>
            </div>
        </div>
    );
};