import { Button } from 'antd';

export default function ButtonNext({ children, onClick }) {
    return (
        <Button type="primary" shape="round" onClick={onClick}
        style={{
            backgroundColor:'#3048C1',
            height:40,
            fontFamily:'Quicksand-SemiBold',
            fontSize:16,
            borderWidth:0,
            width:120
        }}>
            {children}
        </Button>
    );
};