import React, { useState } from "react";

const TextSider = ({chil1, chil2, chil3}) => {

    return (
        <>
            <div className="upk-create-title"
                style={{ fontFamily: 'Quicksand-Bold', fontSize: 45, color: '#094795', marginBottom: -20 }}>
                    {chil1}
            </div>
            <div className="upk-create-title"
                style={{ fontFamily: 'Quicksand-Light', fontSize: 40, color: '#094795' }}>
                    {chil2}
            </div>
            <div className="upk-create-subtitle"
                style={{ fontFamily: 'Quicksand-Regular', fontSize: 18, color: '#094795' }}>
                    {chil3}
            </div>
        </>
    );
};

export default TextSider;