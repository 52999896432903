export const clearLocalStorage = () =>{
    localStorage.removeItem("code");
    localStorage.removeItem("code_type");
    localStorage.removeItem("nome_card");
    localStorage.removeItem("descrizione_card");
    localStorage.removeItem("email");
    localStorage.removeItem("persLogo");
    localStorage.removeItem("persColor");
    localStorage.removeItem("new_logo");
    localStorage.removeItem("color_pers");
};