import React, { useState } from "react";
import { Badge, Layout, message, Steps } from 'antd';
import Step1 from "../components/createbadge/Step1";
import Step2 from "../components/createbadge/Step2";
import Step3 from "../components/createbadge/Step3";
import ButtonNext from "../components/createbadge/ButtonNext";
import ButtonPrev from "../components/createbadge/ButtonPrev";
import TextSider from "../components/TextSider";
import { clearLocalStorage } from "../services/clearStorage";
import { useNavigate } from "react-router-dom";
import { generateBadge, generateBadgeLogo } from "../services/api";
const { Sider, Content } = Layout;
const { Step } = Steps;
const { innerWidth, innerHeight } = window;

const CreateBadge = () => {
    const [current, setCurrent] = useState(0);
    const [continueBut1, setContinueBut1] = useState(false);
    const [continueBut2, setContinueBut2] = useState(false);
    const [continueBut3, setContinueBut3] = useState(false);
    const [persLogo, setPersLogo] = useState();
    const [persColor, setPersColor] = useState();
    const navigate = useNavigate();

    const steps = [
        {
            title: <div style={{ color: current == 0 ? '#094795' : '#B5BBD1', fontFamily: 'Quicksand-SemiBold', fontSize: 18 }}>CARICA CODICE</div>,
            content: <Step1 setContinueBut={setContinueBut1} />,
        },
        {
            title: <div style={{ color: current == 1 ? '#094795' : '#B5BBD1', fontFamily: 'Quicksand-SemiBold', fontSize: 18 }}>PERSONALIZZA</div>,
            content: <Step2 setContinueBut={setContinueBut2} setPersLogo={setPersLogo} setPersColor={setPersColor} />,
        },
        {
            title: <div style={{ color: current == 2 ? '#094795' : '#B5BBD1', fontFamily: 'Quicksand-SemiBold', fontSize: 18 }}>INVIA</div>,
            content: <Step3 setContinueBut={setContinueBut3} />,
        },
    ];

    const next = () => {
        if (current == 0 && continueBut1) {
            setCurrent(current + 1);
        }
        if (current == 1 && continueBut2) {
            setCurrent(current + 1);
        }
        if (current == 2 && continueBut3) {
            setCurrent(current + 1);
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const onCreateBadgeLogo = async (newLogo, data, token) => {
        const res = await fetch(newLogo);
        const buf = await res.arrayBuffer();
        const logo = new File([buf], 'logo.png', { type: 'image/png' });
        generateBadgeLogo(logo, data, token).then(
            () => {
                message.success('Invio avvenuto correttamente');
                clearLocalStorage();
                navigate('/dashboard');
            }
        ).catch((error) => {
            console.log("fail", error);
            message.error('Errore durante l\'invio del Badge, per favore riprovare');
        });
    }

    const onCreateBadge = () => {
        const token = localStorage.getItem('access_token');
        const persLogo = localStorage.getItem('persLogo');
        const persColor = localStorage.getItem('persColor');
        const nameBadge = localStorage.getItem('nome_card');
        const descBadge = localStorage.getItem('descrizione_card');
        const codeType = localStorage.getItem('code_type');
        const code = localStorage.getItem('code');
        let codeArray = [];
        codeArray.push(code);
        codeArray = JSON.stringify(codeArray);
        const data = {
            nome: descBadge,
            testo: nameBadge,
            tipologia: codeType,
            codici: codeArray,
            flg_badge: 1
        };
        let backColor = false;
        if (persColor) {
            backColor = localStorage.getItem('color_pers');
            data.sfondo = backColor;
        }
        if (persLogo) {
            const newLogo = localStorage.getItem('new_logo');
            onCreateBadgeLogo(newLogo, data, token);
            return;
        }
        generateBadge(data, token)
            .then(() => {
                message.success('Invio avvenuto correttamente');
                clearLocalStorage();
                navigate('/dashboard');
            }
            )
            .catch((error) => {
                console.log("fail", error);
                message.error('Errore durante l\'invio del Badge, per favore riprovare');
            });
    };

    return (
        <>
            <Layout style={{ height: '100vh' }}>
                <Content className="upk-create-content" style={{ backgroundColor: 'white', overflowY: 'scroll' }}>
                    <div className="upk-create-content-title"
                        style={{ fontSize: innerWidth > 600 ? 48 : 25, color: '#094795', fontFamily: 'Quicksand-Bold', textAlign: 'center', padding: 20, marginTop:20 }}>
                        Digitalizza il tuo badge
                    </div>
                    <div style={{ padding: innerWidth > 1900 ? innerWidth > 2100 ? 80 : 40 : 20 }}>

                        {innerWidth > 1000 &&
                            <Steps current={current}
                                labelPlacement="vertical">
                                {steps.map((item) => (
                                    <Step key={item.title} title={item.title} />
                                ))}
                            </Steps>
                        }
                        {innerWidth <= 1000 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="upk-create-stepper"
                                style={{ fontFamily: 'Quicksand-SemiBold', fontSize: 30, color: '#094795', marginRight: 15, marginBottom: 6 }}>
                                {current + 1}/3
                            </div>
                            {steps[current].title}</div>
                        }

                        {   innerWidth <=1000 &&
                            <div className="steps-content">
                            <div style={{
                                display: 'flex', justifyContent: 'center',
                                backgroundColor: '#f5f2f2',
                                borderRadius: 15, marginTop: '5vh', width: '100%', marginBottom: 20,
                                minHeight: 400
                            }}
                            >
                                {steps[current].content}
                            </div>
                        </div>}

                        {   innerWidth >1000 &&
                            <div className="steps-content">
                            <div style={{
                                display: 'flex', justifyContent: 'center',
                                backgroundColor: 'white',
                                borderRadius: 15, marginTop: '5vh', width: '90%', marginBottom: 20,
                                minHeight: 400, marginLeft:'5%'
                            }}
                            >
                                {steps[current].content}
                            </div>
                        </div>}

                        {innerWidth <= 1000 &&
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10%', marginBottom: '40%' }}>
                                {current > 0 && (
                                    <ButtonPrev onClick={prev} />
                                )}
                                {current < steps.length - 1 && (
                                    <ButtonNext onClick={next}>AVANTI</ButtonNext>
                                )}
                                {current === steps.length - 1 && (
                                    <ButtonNext onClick={() => {
                                        if (continueBut3) {
                                            onCreateBadge();
                                        }
                                    }}>INVIA</ButtonNext>
                                )}
                            </div>
                        }
                    </div>


                </Content>
                {innerWidth > 1000 &&
                    <Sider width={"40%"}
                        style={{ backgroundColor: '#f5f2f2', display: 'flex', flexDirection: 'column' }}>
                        <div className="upk-create-content-container"
                            style={{ width: '80%', marginLeft: 45, marginTop: '30vh' }}>
                            <div className="upk-create-title-container">
                                <div className="upk-create-stepper"
                                    style={{ fontFamily: 'Quicksand-SemiBold', fontSize: 30, color: '#094795' }}>
                                    {current + 1}/3
                                </div>
                                {(current + 1) == 1 &&
                                    <TextSider chil1="Carica" chil2="Codice QR"
                                        chil3="Inserisci il codice manualmente o carica l'immagine di un codice QR." />
                                }
                                {(current + 1) == 2 &&
                                    <TextSider chil1="Personalizza" chil2="Il tuo Badge"
                                        chil3="Inserisci un titolo alla tua card e seleziona un colore di sfondo." />
                                }
                                {(current + 1) == 3 &&
                                    <TextSider chil1="Controlla" chil2="I dati inseriti"
                                        chil3="Controlla che sia tutto corretto e invia!" />
                                }
                            </div>
                            <div className="steps-action"
                                style={{ paddingTop: 20, display: 'flex', flexDirection: 'row' }}>
                                {current > 0 && (
                                    <ButtonPrev onClick={prev} />
                                )}
                                {current < steps.length - 1 && (
                                    <ButtonNext onClick={next}>AVANTI</ButtonNext>
                                )}
                                {current === steps.length - 1 && (
                                    <ButtonNext onClick={() => {
                                        if (continueBut3) {
                                            onCreateBadge();
                                        }
                                    }}>INVIA</ButtonNext>
                                )}
                            </div>
                        </div>

                    </Sider>}
            </Layout>
        </>
    );
};

export default CreateBadge;