// context.js

import React, { createContext, useReducer } from "react";

const initialState = {
  isLoggedIn: false,
  checked: false,
  setPassword: false,
  user: null,
  token: null,
  isLoadingPage: false,
  isLoadingLogin: false,
  isLoadingLogout: false,
  errorCheck: null,
  errorLogin: null,
  errorLogout: null,
};

export const WebContext = createContext(initialState); // <- initialize Context using initialState

export const WebContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "check_start":
        return { ...state, isLoadingPage: true, checked: false };
      case "check_success":
        return {
          ...state,
          isLoadingPage: false,
          user: action.payload.user,
          token: action.payload.token,
          isLoggedIn: true,
          checked: true,
        };
      case "check_fail":
        return {
          ...state,
          isLoadingPage: false,
          errorCheck: action.payload,
          isLoggedIn: false,
          user: null,
          token: null,
          checked: true,
        };
      case "login_start":
        return { ...state, isLoadingLogin: true };
      case "login_success":
        return {
          ...state,
          isLoggedIn: true,
          isLoadingLogin: false,
          checked: true,
          user: action.payload.user,
          token: action.payload.token,
        };
      case "login_success_set_password":
        return {
          ...state,
          isLoggedIn: false,
          setPassword: true,
          isLoadingLogin: false,
          user: action.payload.user,
          token: action.payload.token,
        };
      case "login_fail":
        return {
          ...state,
          isLoggedIn: false,
          isLoadingLogin: false,
          errorLogin: action.payload,
        };
      case "logout_start":
        return { ...state, isLoadingLogout: true };
      case "logout_success":
        return {
          ...state,
          isLoadingLogout: false,
          user: null,
          token: null,
          checked: false,
          isLoggedIn: false,
        };
      case "logout_fail":
        return {
          ...state,
          isLoadingLogout: false,
          checked: false,
          errorLogout: action.payload,
        };
      case "update_token":
        return { ...state, token: action.payload };

      case "set_password_start":
        return { ...state, isLoadingSetPassword: true };
      case "set_password_success":
        return { ...state, isLoadingSetPassword: false, setPassword: false };
      case "set_password_fail":
        return {
          ...state,
          isLoadingSetPassword: false,
          errorSetPassword: action.payload,
        };
      default:
        return { ...state };
    }
  }, initialState);
  return (
    <WebContext.Provider value={[state, dispatch]}>
      {/* <- this value is gonna be Global state */}
      {children}
    </WebContext.Provider>
  );
};
