import { baseURL } from "../utils/constants";
import axios from "axios";

export const check = (token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(baseURL + "/check", {}, config);
      resolve(response.data.data);
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};

export const login = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(baseURL + "/login", data);
      resolve(response.data.data);
    } catch (error) {
      reject(
          error.response &&
          error.response.data &&
          error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  });
};

export const register = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(baseURL + "/register", data);
        resolve(response.data.data);
      } catch (error) {
        reject(
            error.response &&
            error.response.data &&
            error.response.data.message
            ? error.response.data.message
            : error.message
        );
      }
    });
  };

export const set_password = (data, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post(baseURL + "/setPassword", data, config);
      resolve();
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};


export const resetPassword = (data) => {
  
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post(baseURL + "/resetPassword", data);
      resolve();
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};

export const logout = (token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post(baseURL + "/logout", {}, config);
      resolve();
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};

export const uploadLogo = (file, token) => {
  const headers = {
    Authorization: "Bearer " + token,
    "Content-Type": "multipart/form-data",
  };

  const bodyFormData = new FormData();
  bodyFormData.append("logo", file);

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: "post",
        url: baseURL + "/profilo/updateLogo",
        data: bodyFormData,
        headers: headers,
      });
      resolve(response.data);
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};

export const uploadColor = (data, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post(baseURL + "/profilo/updateSfondo", data, config);
      resolve();
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};

export const profileData = (token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(baseURL + "/profilo", config);
      resolve(response.data.data);
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};

export const getColor = () => {

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(baseURL + "/colori");
      resolve(response.data.data);
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};

export const generateBadge = (data, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post(baseURL + "/genera", data, config);
      resolve();
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};

export const generateBadgeLogo = (file, data, token) => {
  const headers = {
    Authorization: "Bearer " + token,
    "Content-Type": "multipart/form-data",
  };

  const bodyFormData = new FormData();
  bodyFormData.append("logo", file);
  bodyFormData.append("nome", data.nome);
  bodyFormData.append("testo", data.testo);
  bodyFormData.append("tipologia", data.tipologia);
  bodyFormData.append("codici", data.codici);

  if(data.sfondo){
  bodyFormData.append("sfondo", data.sfondo);
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: "post",
        url: baseURL + "/genera",
        data: bodyFormData,
        headers: headers,
      });
      resolve(response.data);
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};

export const resetPsw = (data, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post(baseURL + "/cambiaPassword", data, config);
      resolve();
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};

export const getBadges = (token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(baseURL + "/badges", config);
      resolve(response.data.data);
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};

export const deleteBadge = (data, token) => {
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post("https://multicanalita.doinnovation.it/api/upocket_app/deleteBadge", data, config);
      resolve();
    } catch (error) {
      reject(error.response &&
        error.response.data &&
        error.response.data.message
        ? error.response.data.message
        : error.message);
    }
  });
};