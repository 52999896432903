import React, { useEffect, useState } from "react";
import { Layout, Button, message, Modal } from 'antd';
import FormProfile from "../components/profile/FormProfile";
import BadgePreview from "../components/default/BadgePreview";
import { deleteBadge, getBadges } from "../services/api";
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { innerWidth, innerHeight } = window;
const { Content, Sider } = Layout;
const { confirm } = Modal;

const Badges = () => {
    const [badges, setBadges] = useState([])
    useEffect(() => {
        (async () => {
            fetchBadges();
        })()
    }, [])

    const fetchBadges = async () => {
        const token = localStorage.getItem('access_token');
        try {
            getBadges(token)
                .then((data) => {
                    setBadges(data);
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log("fail", error);
            message.error(error);
        }
    };


    const deleteBadgeHandler = async (e) => {
        const idButton = e.currentTarget.id;
        confirm({
            title: 'Sei sicuro?',
            icon: <ExclamationCircleOutlined />,
            content: 'Il badge verrà eliminato su tutti i tuoi dispositivi.',
            okText: 'Ok',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                // const idButton = e.currentTarget.id;
                const idBadge = idButton.replace('btn_', '');
                const data = {
                    id_badge: idBadge
                }
                try {
                    const token = localStorage.getItem('access_token');
                    deleteBadge(data, token)
                        .then(() => {
                            message.success('Badge eliminato');
                            fetchBadges();
                        })
                } catch (error) {
                    console.log(error);
                }
            },
        });


    }

    return (
        <Layout style={{ height: '100vh' }}>
            <Content className="upk-profile-content" style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', flexDirection: 'column', overflowY: 'scroll' }}>
                <div className="upk-profile-content-title"
                    style={{ fontSize: innerWidth > 600 ? 48 : 25, color: '#094795', fontFamily: 'Quicksand-Bold', textAlign: 'center', padding: 20, marginTop: 20 }}>
                    I miei badge
                </div>
                <div style={{ width: innerWidth < 1000 ? 375 : 600, marginBottom: 80 }}>{badges.length > 0 && badges.map((item) => <div key={item.id} style={{
                    width: '90%', backgroundColor: '#f5f2f2', borderRadius: 20, display: 'flex', flexDirection: innerWidth < 1000 ? 'column' : 'row', alignItems: 'center',
                    marginBottom: 20, marginLeft: '5%'
                }}>
                    <div style={{ margin: '2em' }}>
                        <BadgePreview imageUrl={`data:image/png;base64,${item.logo}`} colorBackground={item.sfondo} title={item.testo} subtitle={item.nome} />
                    </div>
                    <div style={{ marginLeft: 20 }}>
                        <Button id={`btn_${item.id}`}
                            type="primary" shape="round"
                            onClick={deleteBadgeHandler}
                            style={{
                                backgroundColor: '#cc180e',
                                height: 40,
                                fontFamily: 'Quicksand-SemiBold',
                                fontSize: 16,
                                borderWidth: 0,
                                width: 120,
                                marginBottom: innerWidth < 1000 ? 20 : 0,
                            }}>
                            Elimina
                        </Button>
                    </div>
                </div>)}
                </div>
            </Content>
            {
                innerWidth > 1000 &&
                <Sider width={"10%"}
                    style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                </Sider>
            }
        </Layout >
    );
};

export default Badges;