import { ReactComponent as EyeOutlined } from "./../../assets/svg/EyeOutlined.svg";
import { ReactComponent as EyeInvisibleOutlined } from "./../../assets/svg/EyeInvisibleOutlined.svg";
import ButtonLogin from "./ButtonLogin";
import { Button, Form, Input } from 'antd';

export default function FormRegister({ onFinish, onFinishFailed }) {
    return (
        <div className="upk-login-form-container" style={{ marginTop: 30 }}>
            <Form
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size='large'
                requiredMark={false}
            >
                <Form.Item label={<div style={{marginLeft:10}}>Email</div>}
                    name="email"
                    labelCol={{ offset: 0 }}
                    style={{ fontFamily: 'Quicksand-Regular', fontSize: 12, color: '#626476' }}
                    rules={[
                        {
                            required: true,
                            message: 'Inserisci un E-mail!',
                            type: "email",
                        },
                    ]}>
                    <Input style={{ borderRadius: 12, height: 60, marginTop: -15 }} />
                </Form.Item>
                <Form.Item>
                    <Button
                        id="upk-login-submit-button"
                        htmlType="submit">
                    </Button>
                    <ButtonLogin>
                        AVANTI
                    </ButtonLogin>
                </Form.Item>
            </Form>
        </div>
    );
};