import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { CirclePicker } from 'react-color';
import { getColor } from "../services/api";

const { innerWidth, innerHeight } = window;

export default function ColorPicker({ buttonVisible = true, submitVisible = true, onClick = null, onChangeColor }) {
    const [colors, setColors] = useState();
    useEffect(() => {
        getColor()
            .then((data) => {
                setColors(
                    data.map((c) => {
                        return c.colore;
                    })
                )
            })
            .catch((error) => {
                console.log("fail", error);
                message.error(error);
            });
    }, [])


    return (
        <div className="upk-default-color-container"
            style={{
                backgroundColor: '#F5F2F2',
                borderRadius: 15,
                padding: 20,
                paddingTop: 30,
                width: innerWidth < 1000 ? 220 : 420
            }}>
            <CirclePicker
                width={innerWidth < 1000 ? 220 : 420}
                onChange={onChangeColor}
                circleSize={35}
                colors={colors}
            />
            {buttonVisible &&
                <Button
                    type="primary" shape="round" onClick={onClick}
                    disabled={!submitVisible}
                    style={{
                        backgroundColor: submitVisible ? '#3048C1' : 'white',
                        height: 40,
                        fontFamily: 'Quicksand-SemiBold',
                        fontSize: 16,
                        borderWidth: 0.5,
                        borderColor: '#3048C1',
                        width: 120,
                        marginTop: 30,
                    }}>
                    OK
                </Button>
            }
        </div>
    );

};