import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { check } from "../services/api";
import { WebContext } from "../store/web-context";
import { LoadingOutlined } from "@ant-design/icons";

const Loading = (props) => {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(WebContext);
  let location = useLocation();

  useEffect(() => {
    const initCheck = async () => {
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        dispatch({ type: "check_start" });
        try {
          const response = await check(access_token);
          dispatch({
            type: "check_success",
            payload: { token: response.token, user: response },
          });
          if (
            location &&
            location.state &&
            location.state.from &&
            location.state.from.pathname &&
            location.state.from.pathname != "/" &&
            location.state.from.pathname != "/login"
          ) {
            navigate(location.state.from.pathname);
          } else {
            console.log("err")
            navigate("/dashboard");
          }
        } catch (error) {
          dispatch({
            type: "check_fail",
            payload: error,
          });
          navigate("/login");
        }
      } else {
        dispatch({
          type: "check_fail",
          payload: { message: "Token non trovato" },
        });
        navigate("/login");
      }
    };
    initCheck();
  }, []);
  return (
    <div className="azl-loading-container">
      <LoadingOutlined style={{ fontSize: "64px", color: "#023AAE" }} />
    </div>
  );
};

export default Loading;
