import { Button } from 'antd';

export default function ButtonPrev({ onClick }) {
    return (
        <Button
        shape="round"
            style={{
                margin: '0 8px',
                height:40,
                fontFamily:'Quicksand-SemiBold',
                fontSize:16,
                borderWidth:0,
                width:120
            }}
            onClick={onClick}
        >
            INDIETRO
        </Button>
    );
};