import React, { useEffect, useState } from "react";
import { Layout, Row, Col, message, Divider } from 'antd';
import FormDefault from "../components/default/FormDefault";
import BadgePreview from "../components/default/BadgePreview";
import TextSider from "../components/TextSider";
import { profileData } from "../services/api";
const { innerWidth, innerHeight } = window;
const { Content, Sider } = Layout;

const Default = () => {
    const [imageUrl, setImageUrl] = useState();
    const [colorBackground, setColorBackground] = useState();
    const [token, setToken] = useState();

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        setToken(token);
        profileData(token)
            .then((data) => {
                let img = data.default_logo.img;
                img = `data:image/png;base64,${img}`
                setImageUrl(img);
                setColorBackground(data.default_sfondo);

            })
            .catch((error) => {
                console.log("fail", error);
                message.error(error);
            });
    }, [])


    return (
        <Layout style={{ height: '100vh' }}>
            <Content className="upk-default-content" style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', flexDirection: 'column', overflowY: 'scroll', paddingBottom: innerWidth<=1000 ? '25%' : 0 }}>
                <div className="upk-default-content-title"
                    style={{ fontSize: innerWidth > 600 ? 48 : 25, color: '#094795', fontFamily: 'Quicksand-Bold', textAlign: 'center', padding: 20, marginTop: 20 }}>
                    Personalizza il tuo Badge Preferito
                </div>
                <div className="upk-default-container" style={{ width: '100%' }}>
                    <Row>
                        <Col span={innerWidth < 700 ? 18 : 14} offset={innerWidth < 700 ? 3 : 5} style={{ marginBottom: 60 }}>
                            <FormDefault setImageUrl={setImageUrl} setColorBackground={setColorBackground} token={token} colorBackground={colorBackground} />
                        </Col>
                    </Row>
                    {innerWidth < 1000 &&
                        <div style={{ marginTop: 20, marginBottom: 70 }}>
                            <div style={{ fontFamily: 'Quicksand-Light', fontSize: 25, color: '#094795', textAlign: 'center', paddingBottom: 30 }}>
                                Anteprima del tuo Badge
                            </div>
                            <BadgePreview imageUrl={imageUrl} colorBackground={colorBackground} />
                        </div>
                    }
                </div>
            </Content>

            {
                innerWidth > 1000 &&
                <Sider width={"50%"}
                    className="upk-default-content"
                    style={{
                        backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowY: 'scroll',
                        borderLeftColor: 'red', borderLeftWidth: 2
                    }}>
                    <Divider type="vertical" style={{ backgroundColor: '#094795', width: 0.1, height: '80vh', marginTop: '10vh', position: 'absolute', left: 0 }} />
                    <div style={{ display: 'flex', marginTop: '35vh', flexDirection: 'column', }}>
                        <div style={{ width: '90%', marginLeft: 35 }}>
                            <TextSider chil1="Anteprima" chil2="Del tuo Badge"
                                chil3="Modifica il logo e il colore per personalizzare il tuo badge" />
                        </div>
                        <div style={{ marginTop: 40, marginBottom: 40 }}>
                            <BadgePreview imageUrl={imageUrl} colorBackground={colorBackground} />
                        </div>

                    </div>
                </Sider>
            }
        </Layout >
    );
};

export default Default;