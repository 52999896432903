import React, { useEffect, useState } from "react";
import { Layout, Row, Col } from 'antd';
import FormProfile from "../components/profile/FormProfile";
const { innerWidth, innerHeight } = window;
const { Content, Sider } = Layout;

const Profile = () => {
    const [email, setEmail] = useState();

    useEffect(() => {
        const email = localStorage.getItem('email');
        setEmail(email);
      }, [])

    return (
        <Layout style={{ height: '100vh' }}>
            <Content className="upk-profile-content" style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', flexDirection: 'column', overflowY: 'scroll' }}>
                <div className="upk-profile-content-title"
                    style={{ fontSize: innerWidth > 600 ? 48 : 25, color: '#094795', fontFamily: 'Quicksand-Bold', textAlign: 'center', padding: 20, marginTop:20 }}>
                    Profilo Utente
                </div>
                <div className="upk-profile-container" style={{ width: innerWidth < 1000 ? '100%' : '60%', }}>
                    <Row>
                        <Col span={innerWidth < 700 ? 18 : 14} offset={innerWidth < 700 ? 3 : 5} style={{ marginBottom: 60 }}>
                            <FormProfile email={email}/>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Layout >
    );
};

export default Profile;