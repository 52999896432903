
import { Upload } from "antd";
import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

export default function UploadLogo({ beforeUpload, handleChange, preventUpload, image, loading }) {

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload 
            </div>
        </div>
    );

    return (
        <div className="upk-default-upload-container"
            style={{
                paddingTop: 7,
                paddingLeft: 7
            }}
        >
            <Upload
                name="logo"
                listType="picture-card"
                className="logo-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                onDrop={handleChange}
                customRequest={preventUpload}
            >
                {image ? (
                    <img
                        src={image}
                        alt="logo"
                        style={{
                            width: '100%',
                        }}
                    />
                ) : (
                    uploadButton
                )}
            </Upload>
        </div>
    );
}