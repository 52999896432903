import React, { useState } from "react";
import { message, Tooltip } from 'antd';
import ColorPicker from "../ColorPicker";
import UploadLogo from "../UploadLogo";
import { uploadColor, uploadLogo } from "../../services/api";
import { InfoCircleOutlined } from '@ant-design/icons'
const { innerWidth, innerHeight } = window;

export default function FormDefault({ setColorBackground, setImageUrl, token, colorBackground }) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [submitVisible, setSubmitVisible] = useState(false);

  const preventUpload = () => {
    console.log('prevent');
  }

  const beforeUpload = (file) => {
    const isPng = file.type === 'image/png';

    if (!isPng) {
      message.error('Puoi caricare solamente file PNG!');
      setTimeout(() => {
        setLoading(false);
      }, 4000)
    }

    const isLt5M = file.size / 1024 / 1024 < 5;

    if (!isLt5M) {
      message.error('L\'immagine non deve superare i 5MB!');
      setTimeout(() => {
        setLoading(false);
      }, 4000)
    }

    if (isPng && isLt5M) {
      uploadLogo(file, token)
        .then((data) => {
          setLoading(false);
          let img = data.data.default_logo.img;
          img = `data:image/png;base64,${img}`
          setImageUrl(img);
          setImage(img);

        })
        .catch((error) => {
          console.log("fail", error);
          message.error(error);
          setLoading(false);
        });
    }
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
  };

  const onChangeColor = (color) => {
    setSubmitVisible(true);
    setColorBackground(color.hex);
  }
  const onClick = () => {
    uploadColor({ colore: colorBackground }, token)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log("fail", error);
        message.error(error);
      });

    setSubmitVisible(false)
  }

  return (
    <div className="upk-default-form-container"
      style={{
        display: 'flex', flexDirection: 'column',
        justifyContent: 'center', alignItems: 'center'
      }}>
      <div className="upk-default-title-container"
        style={{
          fontFamily: 'Quicksand-Light', fontSize: 25, color: '#094795', textAlign: 'center',
          marginBottom: 30, marginTop: 30
        }}>
        Seleziona o trascina un logo per caricarlo
        <span> </span>
        <Tooltip title="Il logo deve essere in formato png">
          <InfoCircleOutlined
            style={{
              color: 'rgba(0,0,0,.45)',
              fontSize:18
            }}
          />
        </Tooltip>
      </div>
      <UploadLogo beforeUpload={beforeUpload} handleChange={handleChange} preventUpload={preventUpload} image={image} loading={loading} />
      <div className="upk-default-color-picker-container"
        style={{
          marginTop: '20%',
          display: 'flex', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center'
        }}>
        <div className="upk-default-title-container"
          style={{
            fontFamily: 'Quicksand-Light', fontSize: 25, color: '#094795', textAlign: 'center',
            marginBottom: 30
          }}>
          Seleziona il colore di sfondo predefinito del tuo badge
        </div>
        <ColorPicker onClick={onClick} submitVisible={submitVisible} onChangeColor={onChangeColor} />
        <div style={{marginBottom: innerWidth >1000 ? 60 : 0}}/>
      </div>
    </div >
  );
};