import { ReactComponent as EyeOutlined } from "./../../assets/svg/EyeOutlined.svg";
import { ReactComponent as EyeInvisibleOutlined } from "./../../assets/svg/EyeInvisibleOutlined.svg";
import ButtonLogin from "./ButtonLogin";
import { Button, Form, Input,Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons'

export default function FormSetPassword({ onFinish, onFinishFailed }) {
    return (
        <div className="upk-login-form-container" style={{ marginTop: 30 }}>
            <Form
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size='large'
                requiredMark={false}
            >

                <Form.Item
                    label={<div style={{marginLeft:10}}>Password<span> </span>
                    <Tooltip title="La password deve essere lunga almeno 6 caratteri e deve contenere una maiuscola, una minuscola, un numero e un simbolo">
                          <InfoCircleOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        </Tooltip>
                    </div>}
                    labelCol={{ offset: 0 }}
                    name="password"
                    style={{ fontFamily: 'Quicksand-Regular', fontSize: 12, color: '#626476', marginBottom: 30 }}
                    rules={[
                        {
                            required: true,
                            message: 'Inserisci una password!',
                        },
                    ]}>
                    <Input.Password style={{ borderRadius: 12, height: 60, marginTop: -15 }}
                    
                        iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)} />
                </Form.Item>
                <Form.Item
                    label={<div style={{marginLeft:10}}>Ripeti Password</div>}
                    labelCol={{ offset: 0 }}
                    name="password_confirm"
                    style={{ fontFamily: 'Quicksand-Regular', fontSize: 12, color: '#626476', marginBottom: 30 }}
                    rules={[
                        {
                            required: true,
                            message: 'Inserisci nuovamente la password',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error("Le due password inserite non sono identiche!")
                                );
                            },
                        }),
                    ]}>
                    <Input.Password style={{ borderRadius: 12, height: 60, marginTop: -15 }}
                        iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)} />
                </Form.Item>
                <Form.Item>
                    <Button
                        id="upk-login-submit-button"
                        htmlType="submit">
                    </Button>
                    <ButtonLogin>
                        AVANTI
                    </ButtonLogin>
                </Form.Item>
            </Form>
        </div>
    );
};