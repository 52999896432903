import { ReactComponent as LoginLogo } from "./../../assets/svg/LoginLogo.svg";

export default function SiderLogin() {
    return (
        <div className="upk-login-sider-container" style={{}}>
            <div className="upk-login-logo-container" style={{ textAlign: 'center' }}>
                <LoginLogo />
            </div>
            <div className="upk-login-content-title-container" style={{ textAlign: 'center' }}>
                <div className="upk-login-content-title" style={{ fontSize: 48, color: '#094795', fontFamily: 'Quicksand-Bold' }}>
                    <span style={{ color: '#3360EF' }}>U</span>Pocket
                </div>
                <div className="upk-login-content-subtitle" style={{ fontSize: 18, color: '#1C395D', fontFamily: 'Quicksand-Medium' }}>
                    Easy way for all your badges
                </div>
            </div>
        </div>
    );
};