import React, { useContext } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  HashRouter,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import Login from "./../pages/Login";
import Profile from "./../pages/Profile"
import Default from "./../pages/Default"
import Badges from "./../pages/Badges"
import CreateBadge from "./../pages/CreateBadge"
import Dashboard from "./../pages/Dashboard"
import Loading from "./../pages/Loading"
import SideMenu from "./SideMenu"

import { WebContext } from './../store/web-context'

const CheckRoute = () => {
  const [state] = useContext(WebContext);
  let checked = state.checked;
  let location = useLocation();

  if (!checked) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <Outlet />;
};

const MainRoutes = () => {
  return (
    <HashRouter>
      <Routes>

        <Route element={<CheckRoute />}>
          <Route
            path="/dashboard"
            element={
              <SideMenu>
                <Dashboard />
              </SideMenu>
            }
          />
          <Route
            path="/dashboard/create"
            element={
              <SideMenu>
                <CreateBadge />
              </SideMenu>
            }
          />
          <Route
            path="/dashboard/badges"
            element={
              <SideMenu>
                <Badges />
              </SideMenu>
            }
          />
          <Route
            path="/dashboard/profile"
            element={
              <SideMenu>
                <Profile />
              </SideMenu>
            }
          />
          <Route
            path="/dashboard/default"
            element={
              <SideMenu>
                <Default />
              </SideMenu>
            }
          />
          <Route path="/login" element={<Login />} />
        </Route>

        {/* Per Test */}
        {/* <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={
          <SideMenu>
            <Dashboard />
          </SideMenu>
        } />
        <Route path="/dashboard/create" element={
          <SideMenu>
            <CreateBadge />
          </SideMenu>
        } />
        <Route
            path="/dashboard/profile"
            element={
              <SideMenu>
                <Profile />
              </SideMenu>
            }
          />
          <Route
            path="/dashboard/default"
            element={
              <SideMenu>
                <Default />
              </SideMenu>
            }
          /> */}

        <Route path="/" element={<Loading />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </HashRouter>
  );
};

export default MainRoutes;
