import { ReactComponent as RightArrow } from "./../../assets/svg/RightArrow.svg";

export default function LoginButton({ children }) {
    return (

        <div className="upk-login-button" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
        >
            <div style={{
                backgroundColor: '#023AAE', borderTopLeftRadius: 12, borderBottomLeftRadius: 12,
                height: 60, width: '45%', maxWidth: 250, display: 'flex', alignItems: 'center', cursor: 'pointer'
            }}
                onClick={() => { document.getElementById('upk-login-submit-button').click() }}>
                <div style={{
                    display: 'flex', color: 'white',
                    fontFamily: 'Quicksand-Bold', fontSize: 18, paddingLeft: 20
                }}>
                    {children}
                </div>
            </div>
            <div onClick={() => { document.getElementById('upk-login-submit-button').click() }}
                style={{
                    backgroundColor: '#02308E', borderTopRightRadius: 12, borderBottomRightRadius: 12,
                    height: 60, width: '10%', maxWidth: 70, color: 'white', fontFamily: 'Quicksand-Light',
                    fontSize: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
                }}>
                <RightArrow />
            </div>
        </div>
    );
};